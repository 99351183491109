import { IAssigmentsTableData } from "../../../../types/common";
import { IProject } from "../../../../types/project";

export interface ISidebarContentProps {
  activeKey: MENU_KEYS;
  assignmentsUniquelyIdentified: NullableIAssigmentsTableData;
  setActiveKey: React.Dispatch<React.SetStateAction<"preferences" | "layout" | "presentation_preview">>;
};

export const KEYS = ['preferences', 'layout', 'presentation_preview'] as const;
export type MENU_KEYS = typeof KEYS[number];

export type MENU_OPTION = {
  'text': string,
  'icon': string,
  'key': MENU_KEYS,
};

export interface SidebarContentCommon extends Pick<ISidebarContentProps, 'setActiveKey' | 'assignmentsUniquelyIdentified'> {
};

export interface UniquelyIdentifiedProjects extends IProject {
  id: string;
}

export type NullableIProjectArr = UniquelyIdentifiedProjects[] | undefined;
export type NullableIAssigmentsTableData = UniquelyIdentifiedAssigmentsTableData[] | undefined;
export interface UniquelyIdentifiedAssigmentsTableData extends IAssigmentsTableData {
  id: string;
}

export type LayoutScreen = 'layout' | 'asignment' | 'new assignment';