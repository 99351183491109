import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
// ! causes circular dependency - fix this TODO: @balraj
// import { RootState } from '../types/store';
// store
import { RootState } from '../../../store';
// store - actions
import {
  addAssignmentField,
  removeAssignmentField,
  resetLayout,
  toggleCompanyLogoVisibility,
  updateAssignmentField,
  updateLayoutColsPerRow,
  updateTitle, updateDescription
} from '../reducers/presentationSlice';
import { credentialMatchesApi } from '../services/credentialMatches';
// types
import { ICredentialMetaData } from '../types/credential';
// util
import { AUTO_SAVE_DELAY } from '../util/constants';
import { ImgUploadDetails, updateMetadata } from '../util/common';
import { updateCredMetadata } from '../reducers/credentialsSlice';

const autoSaveMiddleware = createListenerMiddleware<RootState>();

autoSaveMiddleware.startListening({
  matcher: isAnyOf(
    updateTitle,
    updateDescription,
    updateLayoutColsPerRow,
    resetLayout,
    toggleCompanyLogoVisibility,
    addAssignmentField,
    removeAssignmentField,
    updateAssignmentField,
    credentialMatchesApi.endpoints.uploadImg.matchFulfilled
  ),
  effect: async (action, listenerApi) => {
    try {
      // Cancel any in-progress instances of this listener
      listenerApi.cancelActiveListeners();

      let imgUploadDetails: ImgUploadDetails | undefined;
      // when uploading company logo there shouldn't be any auto save delay
      if (credentialMatchesApi.endpoints.uploadImg.matchFulfilled(action)) {
        // UI sends additional param in the api do decide from which part of UI the upload happens
        // this is used to update the correct metadata field e.g. assignments_override, company_logo
        const { source, assignmentRef, isAutoSave } = action.meta.arg.originalArgs;
        const { key, message } = action.payload;

        if (!isAutoSave) { // dont auto save uploaded img if not intended
          return;
        }

        imgUploadDetails = {
          key,
          source,
          message,
          assignmentRef,
          isAutoSave
        }
      } else {
        // Debounce auto-save to avoid saving too frequently
        await listenerApi.delay(AUTO_SAVE_DELAY);
      }

      const presentation = listenerApi.getState().presentation;
      const joid = listenerApi.getState().credentialsAppState.joid;
      const {
        credRef,
      } = presentation;
      const credential = listenerApi.getState().credentials.credentials.byId[credRef];

      let metadata: ICredentialMetaData = updateMetadata(
        credential.metadata,
        presentation,
        imgUploadDetails
      );

      await listenerApi.dispatch(credentialMatchesApi.endpoints.updateCred.initiate({
        joid,
        credential: {
          ref: credRef,
          metadata,
          project: credential.project
        }
      }));

      listenerApi.dispatch(updateCredMetadata({
        ref: credRef,
        metadata
      }))

    } catch (error) {
      console.error("Error in effect of autoSaveMiddleware: ", error);
    }
  },
});


export default autoSaveMiddleware;