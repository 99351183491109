import React from "react";
import { commonApis } from "../services/apis";

const useIndustries = () => {
  const [industries, setIndustries] = React.useState<string[]>([]);

  // api
  const { data: industriesList, isLoading: isPrefsLoading } =
    commonApis.getIndustriesList.useQuery({
      staleTime: 1000 * 60 * 60 * 24, // 24 hour
    });

  React.useEffect(() => {
    if (industriesList) {
      setIndustries(industriesList.data);
    }
  }, [industriesList]);

  return {
    industries,
  };
};

export default useIndustries;
