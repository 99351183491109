import styled, { createGlobalStyle } from "styled-components";
import { Layout, theme } from "maui";

// @TODO Move this to the sidebar component
export const SIDEBAR_WIDTH: {
  collapsed: number;
  expanded: number;
} = {
  collapsed: 60,
  expanded: 220,
};

const AppStyles = createGlobalStyle`
  html {
    font-size: 62.5%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    background-color: ${theme.colors["grey-100"]};
  }

  #root {
    height: 100%;
  }

  button {
    cursor: pointer;
  }

  blockquote {
    color: ${theme.colors["grey-500"]};
  }

  .visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  .ant-modal-footer {
    text-align: right !important;
  }
`;

export const StyledLayoutContent = styled.div<{
  $sidebarCollapsed?: boolean;
  $collapsibleWidth: boolean;
}>`
  position: relative;
  left: ${({ $sidebarCollapsed }) =>
    $sidebarCollapsed
      ? `${SIDEBAR_WIDTH.collapsed}px`
      : `${SIDEBAR_WIDTH.expanded}px`};
  transition: left 0.2s;
  width: calc(100% - ${({ $sidebarCollapsed, $collapsibleWidth }) => (!$collapsibleWidth ? '0px' : $sidebarCollapsed ? `${SIDEBAR_WIDTH.collapsed}px` : `${SIDEBAR_WIDTH.expanded}px`)});
  iframe {
    width: calc(
      100% -
        ${({ $sidebarCollapsed }) =>
          $sidebarCollapsed
            ? `${SIDEBAR_WIDTH.collapsed}px`
            : `${SIDEBAR_WIDTH.expanded}px`}
    );
  }
`;

export const MainWrapper = styled.div<{
  $sidebarCollapsed?: boolean;
  $sidebarDisabled?: boolean;
}>`
  padding-left: ${({ $sidebarCollapsed, $sidebarDisabled }) =>
    $sidebarDisabled
      ? "0px"
      : $sidebarCollapsed
      ? SIDEBAR_WIDTH.collapsed + "px"
      : SIDEBAR_WIDTH.expanded + "px"};
  transition: padding 0.1s ease-out; // Match StyledSidebar's transition.
  width: ${({ $sidebarCollapsed }) =>
    $sidebarCollapsed
      ? `calc(100% - ${SIDEBAR_WIDTH.collapsed}px)`
      : `calc(100% - ${SIDEBAR_WIDTH.expanded}px)`};
`;

export const SidebarWrapper = styled.div`
  > div:first-child {
    top: 64px;
  }
`;

export default AppStyles;
