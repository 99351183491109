import { Icon, theme } from 'maui';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as RRALoginLogoIcon } from '../../../assets/images/svgs/rra-login-logo.svg';
import { BreadcrumbIconWrapper, BreadCrumbItem, BreadCrumbItemB, RRALogoSVG, StyledBreadcrumb } from '../Header.styled';

const PATHNAME_LABEL_MAP: {
  [key: string]: {
    label: string;
    icon: string;
    url?: string;
  };
} = {
  'my-apps': {
    label: 'My Apps',
    icon: 'home',
    url: '/my-apps',
  },
  'apps-catalog': {
    label: 'Apps Catalog',
    icon: 'apps',
    url: '/apps-catalog',
  },
  'roadmap': {
    label: 'Roadmap',
    icon: 'map',
    url: '/roadmap',
  },
  'people_finder': {
    label: 'People Finder',
    icon: 'person_search',
  },
  'candidate-mapping': {
    label: 'Candidate Mapping',
    icon: 'account_box',
  },
  'cred-builder': {
    label: 'Cred Builder',
    icon: 'apartment',
    url: '/my-apps/cred-builder',
  },
  // need to see how to handle dynamic cases.
}

const Breadcrumbs = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const pathSnippets = useMemo(() => {
    let _pathSnippets = location.pathname.split('/').filter((path: string) => path);
    _pathSnippets = _pathSnippets.filter((path: string, index: number) => {
      if(PATHNAME_LABEL_MAP[path]){
        return true;
      }
      return false;
    });
    return _pathSnippets;
  }, [location.pathname]);



  const goToUrl = (url: string | undefined) => {
    if(url){
      navigate(url);
    }
  }


  return (
    <StyledBreadcrumb>
      <BreadCrumbItemB>
        <RRALoginLogoIcon style={{ width: 60}} />
      </BreadCrumbItemB>
      {pathSnippets.map((pathSnippet: string, index: number) => {
        return (
          <BreadCrumbItem key={pathSnippet}>
            <BreadcrumbIconWrapper>
              <Icon icon={PATHNAME_LABEL_MAP[pathSnippet]?.icon} style={{ fontSize: 18, marginRight: 4, color: theme.colors['grey-600'] }} />
            </BreadcrumbIconWrapper>
            <span style={{
              cursor: PATHNAME_LABEL_MAP[pathSnippet]?.url ? 'pointer' : 'default',
            }} onClick={() => goToUrl(PATHNAME_LABEL_MAP[pathSnippet].url)}>
              {PATHNAME_LABEL_MAP[pathSnippet]?.label}
            </span>
          </BreadCrumbItem>
        )
      })}
    </StyledBreadcrumb>
  );
}

export default Breadcrumbs;