import axios from "axios";
import { matchesUrl } from "findem-helpers";
import { router } from 'react-query-kit';
import { keepPreviousData } from "@tanstack/react-query";
import { ICreateCredentialPayload, ICredential } from "../components/CredentialApp/types/credential";

export interface ICredentialsFIlter {
  field: string;
  operator: string;
  value: string;
}
export interface IGetCredentialsParams {
  joid: string;
  page: number;
  size: number;
  filters: ICredentialsFIlter[];
}

export interface ICredentialParam extends Omit<ICredential, 'ref' | 'project'> {
  ref?: string;
  joid: string;
  project?: {
    project_id: string;
  };
}

const credential = {
  getCredentials: router.query({
    fetcher: (variables: IGetCredentialsParams) => axios.post(`${matchesUrl}/hm/api/credbuilder/search?joid=${variables.joid}`, {
      page: variables.page,
      size: variables.size,
      filters: variables.filters,
      joid: variables.joid
    }, {
      withCredentials: true
    }),
    placeholderData: keepPreviousData,
  }),
  createUpdateCredential: router.mutation({
    mutationFn: async (variables: ICreateCredentialPayload) => {
      return axios.post(`${matchesUrl}/hm/api/credbuilder`, variables, {
        withCredentials: true
      });
    },
  }),
  getCredentialById: router.query({
    fetcher: (variables: { ref: string, joid: string }) => axios.get(`${matchesUrl}/hm/api/credbuilder?ref=${variables.ref}&joid=${variables.joid}`, {
      withCredentials: true
    }),
  }),
};

export default credential;