import {
  createSlice,
  createSelector
} from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { CredRef, ICredential } from '../types/credential';


// TODO: balraj - lookups by CredRef can fail if data is corrupt
// e.g. lookup by xyz ref wont always result in a cred object existing with that ref
// - how can typescript help here - typeguards maybe?
export interface ICredentialsState {
  credentials: {
    byId: Record<CredRef, ICredential>;
    allIds: CredRef[];
  }
};

const initialState: ICredentialsState = {
  credentials: {
    byId: {},
    allIds: [],
  }
}

export const credentialsSlice = createSlice({
  name: 'credentials',
  initialState,
  reducers: {
    createCred: (state, action: PayloadAction<ICredential>) => {
      const ref = action.payload.ref;
      state.credentials.allIds.push(ref);
      state.credentials.byId[ref] = action.payload;
    },
    updateCred: (state, action: PayloadAction<ICredential>) => {
      const ref = action.payload.ref;
      state.credentials.byId[ref] = action.payload;
    },
    removeCredById: (state, action: PayloadAction<CredRef>) => {
      const ref = action.payload;
      delete state.credentials.byId[action.payload];
      state.credentials.allIds = state.credentials.allIds.filter(id => id !== ref);
    },
    updateCredName: (state, action: PayloadAction<{
      ref: ICredential['ref'],
     name: ICredential['name']
    }>) => {
      const ref = action.payload.ref;
      const name = action.payload.name;

      state.credentials.byId[ref]['name'] = name;
    },
    updateCredProject: (state, action: PayloadAction<{
      ref: ICredential['ref'],
      project: ICredential['project']
    }>) => {
      const ref = action.payload.ref;
      const project = action.payload.project;

      state.credentials.byId[ref]['project'] = project;
    },
    updateCredRequirements: (state, action: PayloadAction<{
      ref: ICredential['ref'],
      requirements: ICredential['requirements']
    }>) => {
      const ref = action.payload.ref;
      const requirements = action.payload.requirements;

      state.credentials.byId[ref]['requirements'] = requirements;
    },
    updateCredMetadata: (state, action: PayloadAction<{
      ref: ICredential['ref'],
      metadata: ICredential['metadata']
    }>) => {
      const ref = action.payload.ref;
      const metadata = action.payload.metadata;

      state.credentials.byId[ref]['metadata'] = metadata;
    },
    updateCredIsDirty: (state, action: PayloadAction<{
      ref: ICredential['ref'],
      isDirty: ICredential['isDirty']
    }>) => {
      const ref = action.payload.ref;
      const isDirty = action.payload.isDirty;

      state.credentials.byId[ref]['isDirty'] = isDirty;
    },
    addCredShortlists: (state, action: PayloadAction<{
      ref: ICredential['ref'],
      shortlist: ICredential['shortlist']
    }>) => {
      const ref = action.payload.ref;
      state.credentials.byId[ref]['shortlist'].unshift(...action.payload.shortlist);
    },
    removeCredShortlists: (state, action: PayloadAction<{
      ref: ICredential['ref'],
      shortlist: ICredential['shortlist']
    }>) => {
      const ref = action.payload.ref;
      const refsToRemove = new Set(action.payload.shortlist.map(s => s.ref));

      state.credentials.byId[ref].shortlist = state.credentials.byId[ref].shortlist.filter(
        item => !refsToRemove.has(item.ref)
      );
    },
    addCreds: (state, action: PayloadAction<ICredential[]>) => {
      const allCredRefs: ICredentialsState['credentials']['allIds'] = [];
      const credsByIds: ICredentialsState['credentials']['byId'] = {};

      action.payload.forEach((cred) => {
        allCredRefs.push(cred.ref);
        credsByIds[cred.ref] = cred;
      });

      state.credentials.allIds = allCredRefs;
      state.credentials.byId = credsByIds;
    }
  },
});

// Accessors
export const selectAllCredentials = createSelector(
  (state: RootState) => state.credentials.credentials.byId,
  (byId) => {
    return Object.values(byId);
  }
);

export const selectCredByRef = createSelector(
  (state: RootState) => state.credentials.credentials.byId,
  (_: RootState, ref: CredRef) => ref,
  (byId, ref) => byId[ref]
);

export const selectCredsByProjectRef = createSelector(
  (state: RootState) => state.credentials.credentials.byId,
  (_: RootState, projectRef: string) => projectRef,
  (byId, projectRef) => {
    return Object.values(byId).filter(credential => credential.project.ref === projectRef);
  }
);

export const {
  createCred,
  updateCred,
  removeCredById,
  updateCredName,
  updateCredProject,
  updateCredRequirements,
  updateCredMetadata,
  updateCredIsDirty,
  addCredShortlists,
  addCreds,
  removeCredShortlists
} = credentialsSlice.actions;

export default credentialsSlice.reducer;