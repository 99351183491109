import {
  createSelector,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
// types
import { NullableIAssigmentsTableData, NullableIProjectArr } from '../components/Credential/Presentation/types/common';
import {
  createGridTemplateAreasTemplateStandard,
  DEFAULT_ASSIGNMENTS_COL_TEMPLATE_STANDARD,
  generateGridTemplateColumnsTemplateStandard,
  GRID_AREA_PREFIX, MAX_APPLIED_FIELDS,
  MAX_COLS_TEMAPLTE_STANDARD
} from '../components/Credential/Presentation/util/standardTemplate';
import { areArraysEqual, AsisgnmentTableColumnsKeys, AssignmentTableColumnsKeysType } from '../util/common';
import { DEFAULT_TEMAPLTE } from '../components/Credential/Presentation/util/util';
import { CredRef, ICredential, TEMPLATE_KEYS_TYPE, TEMPLATE_META_DATA } from '../types/credential';
import { RootState } from '../../../store';
import { credentialMatchesApi } from '../services/credentialMatches';
import { decodeString } from 'findem-helpers';
import { IAssigmentsTableData } from '../types/common';

export type SortableAsisgnmentTableColumnsKeysType = {
  id: string;
  columnKey: AssignmentTableColumnsKeysType
}

type LayoutOptions = {
  colsPerRow: number;
  isCompanyLogoVisible: boolean;

  assignmentFields: SortableAsisgnmentTableColumnsKeysType[];
}

export type PresentationState = {
  credRef: CredRef;
  // form state
  title: string;
  description: string;
  // css
  display: string;
  gridTemplateColumns: string;
  gridTemplateAreas: string;
  // template
  templateKey: TEMPLATE_KEYS_TYPE;
  // layout state
  colsPerRow: number;
  assignments: NullableIAssigmentsTableData;
  isCompanyLogoVisible: boolean;
  // css info -
  // ! THIS IS CRITICAL - FIX TYPES HERE - EMPTY ARR ELEMENT WONT BE KNOWN - TUPLE CAN HAVE UNDEFINED OR NOT PRESENT VALUE
  // @TODO: balraj better types here maybe - this is a tuple whose length is determined by MAX Allowed Applied Fields and each of the element in the type can be empty/optional
  // Also due to DND toolkit we are complicating type by adding 'id' - see if can remove
  assignmentFields: SortableAsisgnmentTableColumnsKeysType[];
}

export const DEFAULT_APPLIED_FIELDS_STANDARD_TEMPLATES = [
  {
    id: AsisgnmentTableColumnsKeys[0],
    columnKey: AsisgnmentTableColumnsKeys[0]
  },
  {
    id: AsisgnmentTableColumnsKeys[5],
    columnKey: AsisgnmentTableColumnsKeys[5]
  }
];

export const extractAssignmentsFromGridTemplateColumns = (gridTemplateAreas: string): string[] => {
  const extractedAssignments = gridTemplateAreas
    .split(" ")
    .filter((datum) => datum.startsWith(GRID_AREA_PREFIX))
    .map((datum) => datum.split("_")[2]);

  return extractedAssignments;
};

// checks if the css template saved is valid w.r.t to the saved assignments or not
// true if valid else false
export const validateTemplate = (assignments: string[], gridTemplateAreas: string): boolean => {
  const extractedAssignments: string[] = extractAssignmentsFromGridTemplateColumns(gridTemplateAreas);

  return areArraysEqual(assignments, extractedAssignments);
}

export const updateGridTemplate = (state: PresentationState) => {
  if (state.assignments) {
    state.gridTemplateAreas = createGridTemplateAreasTemplateStandard(
      Math.ceil(state.assignments.length / state.colsPerRow),
      state.colsPerRow,
      GRID_AREA_PREFIX,
      state.assignments.map((item) => (item.ref)),
      MAX_COLS_TEMAPLTE_STANDARD
    );
    state.gridTemplateColumns = generateGridTemplateColumnsTemplateStandard(
      state.colsPerRow,
      MAX_COLS_TEMAPLTE_STANDARD
    );
  }
};

export const initialState = {
  credRef: '',
  assignments: undefined,
  // form
  title: '',
  description: '',
  // css
  display: '',
  gridTemplateAreas: '',
  gridTemplateColumns: '',
  // layout
  colsPerRow: DEFAULT_ASSIGNMENTS_COL_TEMPLATE_STANDARD,
  isCompanyLogoVisible: true,
  // eg - position title, start date
  assignmentFields: DEFAULT_APPLIED_FIELDS_STANDARD_TEMPLATES,
  templateKey: DEFAULT_TEMAPLTE
} satisfies PresentationState as PresentationState;

export const presentationSlice = createSlice({
  name: "presentation",
  initialState,
  reducers: {
    updateTitle: (state, action: PayloadAction<{ title: string }>) => {
      state.title = action.payload.title;
    },
    updateDescription: (state, action: PayloadAction<{ description: string }>) => {
      state.description = action.payload.description;
    },
    updateAssignments: (state, action: PayloadAction<NullableIAssigmentsTableData>) => {
      state.assignments = action.payload;
      updateGridTemplate(state);
    },
    updateLayoutColsPerRow: (state, action: PayloadAction<LayoutOptions['colsPerRow']>) => {
      state.colsPerRow = action.payload;
      updateGridTemplate(state);
    },
    resetLayout: (state) => {
      state.colsPerRow = initialState.colsPerRow;
      state.assignmentFields = initialState.assignmentFields;
      state.isCompanyLogoVisible = initialState.isCompanyLogoVisible;

      updateGridTemplate(state);
    },
    toggleCompanyLogoVisibility: (state) => {
      state.isCompanyLogoVisible = !state.isCompanyLogoVisible;
    },
    addAssignmentField: (state, action: PayloadAction<AssignmentTableColumnsKeysType>) => {
      if (state.assignmentFields.length === MAX_APPLIED_FIELDS) {
        state.assignmentFields[MAX_APPLIED_FIELDS - 1] = { id: action.payload, columnKey: action.payload };
      } else {
        state.assignmentFields.push({ id: action.payload, columnKey: action.payload });
      }
    },
    removeAssignmentField: (state, action: PayloadAction<AssignmentTableColumnsKeysType>) => {
      state.assignmentFields = state.assignmentFields.filter((appliedField) => appliedField.columnKey !== action.payload);
    },
    updateAssignmentField: (state, action: PayloadAction<SortableAsisgnmentTableColumnsKeysType[]>) => {
      state.assignmentFields = action.payload;
    },
    reset: (state) => {
      return initialState;
    },
    buildStateFromCredByRef: (state, action: PayloadAction<ICredential>) => {
       const metadata = action.payload.metadata;
        // initialise client-side state from server-side state
        state.credRef = action.payload.ref;

        // assignemnts search api call gets skipped if nothing is shortlisted so the extra reducer in this reducer for that matchFulfilled
        // doesnt get run
        if (action.payload.shortlist.length === 0) {
          Object.assign(state, initialState);

          return;
        }
        if (metadata) {
          const activeTemplate = metadata.active_template;
          const templateMetadata = metadata[activeTemplate];
          const css = templateMetadata?.css;

          // initialise client-side state from server-side state
          state.title = metadata[activeTemplate]?.slide_title ?? initialState.title;
          state.description = metadata[activeTemplate]?.slide_description ?? initialState.description;
          state.colsPerRow = metadata[activeTemplate]?.cols_per_row ?? initialState.colsPerRow;
          state.assignmentFields = metadata[activeTemplate]?.assignment_fields?.map((field) => ({ id: field, columnKey: field })) ?? initialState.assignmentFields;
          state.isCompanyLogoVisible = metadata[activeTemplate]?.is_company_logo_visible ?? initialState.isCompanyLogoVisible;
          state.templateKey = activeTemplate;


          if (css) {
            state.display = css.display;
            state.gridTemplateColumns = css['grid-template-columns'];
            state.gridTemplateAreas = css['grid-template-areas'];
          }
        } else {
          state.title = initialState.title;
          state.description = initialState.description;
          state.colsPerRow = initialState.colsPerRow;
          state.assignmentFields = initialState.assignmentFields;
          state.isCompanyLogoVisible = initialState.isCompanyLogoVisible;
          state.templateKey = initialState.templateKey;
        }
    },
    buildAssignmentsAndGridTemplate: (state, action: PayloadAction<IAssigmentsTableData[]>) => {
      const assignments = action.payload;
      state.assignments = assignments.map((assignment) => ({ ...assignment, 'id': assignment.ref }));
      const assignmentsRefs: string[] = assignments.map((assignment) => assignment.ref);
      // check if the assignments have changed then only update the grid template or if the template doesnt exist at all
      // validateTemplate - makes sure that if for some reason the css template saved wasnt updated w.r.t to the current shortlisted assignments
      // we then alteast have a fallback so it renders the layout instead of showing blank screen
      if (!validateTemplate(assignmentsRefs, state.gridTemplateAreas) || !state.gridTemplateAreas || !state.gridTemplateColumns || !state.display) {
        updateGridTemplate(state);
        state.display = 'grid';
      }
    }
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addMatcher(credentialMatchesApi.endpoints.getAssignments.matchFulfilled, (state, action) => {
  //       const assignments = action.payload.data;
  //       state.assignments = assignments.map((assignment) => ({ ...assignment, "id": assignment.ref }));
  //       if (!state.gridTemplateAreas || !state.gridTemplateColumns || !state.display) {
  //         updateGridTemplate(state);
  //         state.display = 'grid';
  //       }
  //     })
  // }
});

export const selectAssignments = (state: RootState) => state.presentation.assignments;
export const selectTitle = (state: RootState) => state.presentation.title;
export const selectDescription = (state: RootState) => state.presentation.description;
export const selectColsPerRow = (state: RootState) => state.presentation.colsPerRow;
export const selectCompanyLogoVisibility = (state: RootState) => state.presentation.isCompanyLogoVisible;
export const selectAppliedFields = (state: RootState) => state.presentation.assignmentFields;
export const selectTemplateKey = (state: RootState) => state.presentation.templateKey;
export const selectGridTemplateColumns = (state: RootState) => decodeString(state.presentation.gridTemplateColumns);
export const selectGridTemplateAreas = (state: RootState) => decodeString(state.presentation.gridTemplateAreas);
export const selectPresentation = (state: RootState) => state.presentation;

export const selectFilteredAssignments = createSelector(
  (state: RootState) => state.presentation.assignments,
  (_: RootState, gridTemplateAreas: PresentationState['gridTemplateAreas']) => gridTemplateAreas,
  (assignments, gridTemplateAreas) => {
    return assignments?.filter((datum) => gridTemplateAreas.includes(datum.ref));
  }
);

export const {
  updateTitle,
  updateDescription,
  updateAssignments,
  updateLayoutColsPerRow,
  resetLayout,
  toggleCompanyLogoVisibility,
  addAssignmentField,
  removeAssignmentField,
  updateAssignmentField,
  reset,
  buildStateFromCredByRef,
  buildAssignmentsAndGridTemplate
} = presentationSlice.actions;

export default presentationSlice.reducer;