import { configureStore } from '@reduxjs/toolkit';
// reducers
import credentialsReducer from './components/CredentialApp/reducers/credentialsSlice';
import credentialsAppSliceReducer from './components/CredentialApp/reducers/credentialAppSlice';
import presentationSliceReducer from './components/CredentialApp/reducers/presentationSlice';
// middleware
import autoSaveMiddleware from './components/CredentialApp/middleware/autoSaveMiddleware';
// rtk
import { pubApi } from './services/pub';
import { credentialMatchesApi } from './components/CredentialApp/services/credentialMatches';

export const store = configureStore({
  reducer: {
    credentials: credentialsReducer,
    credentialsAppState: credentialsAppSliceReducer,
    presentation: presentationSliceReducer,
    [pubApi.reducerPath]: pubApi.reducer,
    [credentialMatchesApi.reducerPath]: credentialMatchesApi.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(autoSaveMiddleware.middleware).concat(pubApi.middleware, credentialMatchesApi.middleware)
});

// export type RootState = ReturnType<typeof store.getState>;
//! This is done to remove circular depency on RootState in listener middleware - look for workaround so that no need to do this
export interface RootState {
  credentials: ReturnType<typeof credentialsReducer>;
  credentialsAppState: ReturnType<typeof credentialsAppSliceReducer>;
  presentation: ReturnType<typeof presentationSliceReducer>;
  [pubApi.reducerPath]: ReturnType<typeof pubApi.reducer>;
  [credentialMatchesApi.reducerPath]: ReturnType<typeof credentialMatchesApi.reducer>;
}
export type AppDispatch = typeof store.dispatch;