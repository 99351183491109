import { Button } from "maui";
import styled from "styled-components";

export const CredBuilderContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${({ theme }) => theme.colors['white']};
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.dimensions.spacing[4]} ${({ theme }) => theme.dimensions.spacing[5]};
  border-bottom: 1px solid ${({ theme }) => theme.colors['grey-300']};

  > p {
    color: ${({ theme }) => theme.colors['grey-500']};
    font-size: 14px;
    margin-bottom: 0px;
  }
`

export const NewCredentialButton = styled(Button)`
  margin-top: ${({ theme }) => theme.dimensions.spacing[2]};
  width: 200px;
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;