import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ICompanySlideoutProps } from "../../CompanySlideout/CompanySlideout";
import { RootState } from "../../../store";
import { credentialMatchesApi } from "../services/credentialMatches";

type ICompanySlideoutValues = Omit<ICompanySlideoutProps, 'handleNavigateAway'>;
export type AssignmentsSlideOut = ICompanySlideoutValues[] | undefined;

export type AssignmentsLookup = {
  isOpen: boolean;
  joid: string;
};

export interface CredentialAppState {
  joid: string;
  assignmentsSlideOut: AssignmentsSlideOut;
  assignmentsLookup: AssignmentsLookup;
  selectedFilterGroup: string | undefined;
  sid: string | null;
  currentPage: number;
  saveCredentialStatus: 'fulfilled' | 'rejected' | 'pending';
}

const initialState: CredentialAppState = {
  joid: '',
  assignmentsSlideOut: undefined,
  assignmentsLookup: {
    isOpen: false,
    joid: "",
  },
  selectedFilterGroup: undefined,
  sid: null,
  currentPage: 1,
  saveCredentialStatus: 'fulfilled'
};

export const credentialsAppSlice = createSlice({
  name: "credentialsAppState",
  initialState,
  reducers: {
    addAssignmentsSlideOut: (
      state,
      action: PayloadAction<ICompanySlideoutValues>
    ) => {
      if (state.assignmentsSlideOut === undefined) {
        state.assignmentsSlideOut = [action.payload];
      } else if (
        Array.isArray(state.assignmentsSlideOut) &&
        state.assignmentsSlideOut.at(-1)?.companyId !==
          action.payload?.companyId
      ) {
        // check if last elem in array is not the same as the incoming elem
        state.assignmentsSlideOut.push(action.payload);
      }
    },
    removeCurrentProfileDrawer: (state) => {
      state.assignmentsSlideOut?.pop();
    },
    closeAllProfileDrawer: (state) => {
      state.assignmentsSlideOut = undefined;
    },
    showAssignmentsLookup: (
      state,
      action: PayloadAction<{
        joid: string;
      }>
    ) => {
      state.assignmentsLookup.isOpen = true;
      state.assignmentsLookup.joid = action.payload.joid;
    },
    hideAssignmentsLookup: (state) => {
      state.assignmentsLookup.isOpen = false;
    },
    updateSelectedFilterGroup: (state, action: PayloadAction<string>) => {
      state.selectedFilterGroup = action.payload;
    },
    updateSid: (state, action: PayloadAction<string | null>) => {
      state.sid = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    // TODO: @balraj can these be consolidated into a single matcher
    builder
      .addMatcher(credentialMatchesApi.endpoints.updateCred.matchFulfilled, (state, action) => {
        state.saveCredentialStatus = action.meta.requestStatus;
      })
      .addMatcher(credentialMatchesApi.endpoints.updateCred.matchPending, (state, action) => {
        state.saveCredentialStatus = action.meta.requestStatus;
      })
      .addMatcher(credentialMatchesApi.endpoints.updateCred.matchRejected, (state, action) => {
        state.saveCredentialStatus = action.meta.requestStatus;
        // !TODO: @balraj handle this
      })
      .addMatcher(credentialMatchesApi.endpoints.credByRef.matchPending, (state, action) => {
        // ! TODO: @balraj - clean this up - joid is being used as a workaround here due to joid being currently set in context - DO either or, dont do both, else have a reason for both
        state.joid = action.meta.arg.originalArgs.joid
      })
  }
});

// Accessors
export const selectAssignmentsSlideOut = (state: RootState) =>
  state.credentialsAppState.assignmentsSlideOut?.at(-1);
export const selectAssignmentsLookup = (state: RootState) =>
  state.credentialsAppState.assignmentsLookup;
export const selectSaveCredentialStatus = (state: RootState) =>
  state.credentialsAppState.saveCredentialStatus;

export const getSelectedFilterGroup = (state: RootState) =>
  state.credentialsAppState.selectedFilterGroup;

export const {
  addAssignmentsSlideOut,
  removeCurrentProfileDrawer,
  closeAllProfileDrawer,
  showAssignmentsLookup,
  hideAssignmentsLookup,
  updateSelectedFilterGroup,
  updateSid,
  setCurrentPage,
} = credentialsAppSlice.actions;

export default credentialsAppSlice.reducer;
