import { BaseQueryApi } from '@reduxjs/toolkit/query/react';
import { FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { matchesUrl } from 'findem-helpers';
import { MOCK_ENDPOINTS } from '../mockAPIs/mock-endpoints';
type MockResponse = Promise<{ data: any }>;
export interface ICustomFetchArgs extends FetchArgs {
  endpointName?: string;
  endpointFunc?: () => MockResponse;
}
const baseQuery = fetchBaseQuery({ baseUrl: matchesUrl });
const customBaseQuery = async (args: ICustomFetchArgs, api: BaseQueryApi, extraOptions: {}, baseUrl: string) => {
  const { endpointName, endpointFunc } = args;
  if (endpointName && endpointFunc && MOCK_ENDPOINTS.includes(endpointName)) {
    // Use the mock API endpoint
    return endpointFunc() as MockResponse;
  }
  // Use the original API endpoint
  return baseQuery({ ...args, ...{ url: baseUrl && !args.url.includes('https://') ? `${baseUrl}${args.url.startsWith('/') ? '' : '/'}${args.url}` : args.url } }, api, extraOptions);
};
export default customBaseQuery;