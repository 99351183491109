import { Loader } from "maui";
import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRouteWrapper from "../components/PermissionedComponents/PrivateRouteWrapper";
import LostPath from "../components/PermissionedComponents/ResourceNotFound";
import InstalledApp from "../components/InstalledApp/InstalledApp";

const MyApps = React.lazy(() => import('../pages/MyApps'));
const AppsCatalog = React.lazy(() => import('../pages/AppsCatalog'));
const RoadmapPage = React.lazy(() => import('../pages/Roadmap'));
const RoadmapDetailsPage = React.lazy(() => import('../pages/RoadmapDetails'));
const DashboardsPage = React.lazy(() => import('../pages/Dashboards'));
const Credential = React.lazy(() => import('../components/CredentialApp/components/Credential/Credential'));

interface ICustomRoutesProps {
  isSidebarCollapsed: boolean;
}

const CustomRoutes = (props: ICustomRoutesProps) => {

  const { isSidebarCollapsed } = props;

  return (
    <PrivateRouteWrapper>
      <Routes>
        <Route
          path="*"
          key={'no-path-found'}
          element={
            <LostPath />
          }
        />

        <Route path="/" element={
          <Navigate to="/my-apps" />
        }
        />

        <Route path="/my-apps">
          <Route index key={'my-apps'}
            element={
              <Suspense fallback={<Loader $fullPage={true} />}>
                <MyApps
                  isSidebarCollapsed={isSidebarCollapsed}
                />
              </Suspense>
            }
          />
          <Route
            path=":id"
            key={'my-apps/:id'}
            element={
              <Suspense fallback={<Loader $fullPage={true} />}>
                <InstalledApp />
              </Suspense>
            }
          />
          <Route
            path="/my-apps/cred-builder/credentials/:credentialId"
            element={
              <Suspense fallback={<Loader $fullPage={true} />}>
                <Credential/>
              </Suspense>
            }
          />
        </Route>

        <Route path="/apps-catalog">
          <Route index key={'apps-catalog'}
            element={
              <Suspense fallback={<Loader $fullPage={true} />}>
                <AppsCatalog
                  isSidebarCollapsed={isSidebarCollapsed}
                />
              </Suspense>
            }
          />
          <Route
            path=":id"
            key={'apps-catalog/:id'}
            element={
              <Suspense fallback={<Loader $fullPage={true} />}>
                <InstalledApp />
              </Suspense>
            }
          />
        </Route>

        <Route
          path="/roadmap"
          key={'roadmap'}
          element={
            <Suspense fallback={<Loader $fullPage={true} />}>
              <RoadmapPage />
            </Suspense>
          }
        />

        <Route
          path="/roadmap/:id"
          key={'roadmap'}
          element={
            <Suspense fallback={<Loader $fullPage={true} />}>
              <RoadmapDetailsPage />
            </Suspense>
          }
        />

        <Route
          path="/dashboards"
          key={'dashboards'}
          element={
            <Suspense fallback={<Loader $fullPage={true} />}>
              <DashboardsPage
                isSidebarCollapsed={isSidebarCollapsed}
              />
            </Suspense>
          }
        />

        <Route
          path="/rra-universe"
          key={'rra-universe'}
          element={
            <>RRA Universe</>
          }
        />
      </Routes>
    </PrivateRouteWrapper>
  );
}

export default CustomRoutes;