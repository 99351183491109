import { Input, Select } from "maui";
import styled, { css } from "styled-components";

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.dimensions.spacing[2]} ${({ theme }) => theme.dimensions.spacing[4]};
`;

export const SelectCompWithMargin = styled(Select)`
  margin-right: ${({ theme: { dimensions } }) => dimensions.spacing[1]};

  &.active {
    &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background: ${({ theme: { colors } }) => colors['blue-100']};
      border-color: ${({ theme: { colors } }) => colors['blue-300']};
      color: ${({ theme: { colors } }) => colors['blue-400']};
    }

    .maui-icon {
      color: ${({ theme: { colors } }) => colors['blue-300']};
    }
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: none;
    border-color: ${({ theme: { colors } }) => colors['grey-400']};
    border-radius: 100px;
    color: ${({ theme: { colors } }) => colors['grey-500']};
    min-width: 100px;
    padding-left: ${({ theme: { dimensions } }) => dimensions.spacing[2]};
    padding-right: ${({ theme: { dimensions } }) => dimensions.spacing[2]};
  }

  &.ant-select-single.ant-select-show-arrow {
    .ant-select-selection-placeholder {
      color: ${({ theme: { colors } }) => colors['grey-500']};
      padding-right: ${({ theme: { dimensions } }) => dimensions.spacing[3]};
    }
  }

  .ant-select-clear {
    background: ${({ theme: { colors } }) => colors.white};
    border-radius: 50%;
    margin-right: 4px;
  }
`;