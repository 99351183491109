import { decodeString } from "findem-helpers";

export const LAYOUT_ASSIGNMENTS_PER_ROW_TEMPLATE_STANDARD: Record<string, string | number>[] = [
  {
    value: 5,
    label: '5 per row'
  },
  {
    value: 4,
    label: '4 per row'
  },
  {
    value: 3,
    label: '3 per row'
  },
  {
    value: 2,
    label: '2 per row'
  }
];

export const DEFAULT_ASSIGNMENTS_COL_TEMPLATE_STANDARD = 5;
export const DEFAULT_ASSIGNMENTS_ROW_TEMPLATE_STANDARD = 4;
export const MAX_COLS_TEMAPLTE_STANDARD = 5;
export const MAX_APPLIED_FIELDS = 3;
export const GRID_AREA_PREFIX = 'grid_area_';

export const generateGridTemplateColumnsTemplateStandard = (x: number, y: number = MAX_COLS_TEMAPLTE_STANDARD): string => {
  if (x === y) {
    return `repeat(${y}, 1fr)`; // All columns are equal
  } else if (x < y) {
    const offset = (y - x) * 0.5; // Calculate fractional offset
    return `${offset}fr repeat(${x}, 1fr) ${offset}fr`;
  }
  return decodeString(`repeat(${y}, 1fr)`);
};

export const createGridTemplateAreasTemplateStandard = (
  rows: number,
  columns: number,
  prefix: string,
  value: string[],
  maxColumns: number = MAX_COLS_TEMAPLTE_STANDARD // max columns in the layout
) => {
  let areas = [];
  let count = 0;

  for (let r = 0; r < rows; r++) {
    let rowAreas = [];

    // Add an empty column at the start if necessary
    if (maxColumns > columns) {
      rowAreas.push('.');
    }

    // Fill actual grid areas
    for (let c = 0; c < columns; c++) {
      let gridAreaName = value[count] ? `${prefix}${value[count]}` : '.';

      rowAreas.push(gridAreaName);
      count++;
    }

    // Add an empty column at the end if necessary
    if (maxColumns > columns) {
      rowAreas.push('.');
    }

    areas.push(`'${rowAreas.join(' ')}'`);
  }

  return decodeString(areas.join(' '));
};