import { useCallback, useContext } from "react";
// api
import { useUpdateCredMutation } from "../../../../services/credentialMatches";
// types
import { ICredential, IUpdateCredentialPayload } from "../../../../types/credential";
// context
import UserContext from "../../../../../../contexts/UserContext";
// custom hooks
import { useCredentialAppDispatch } from "../../../../hooks/storeHooks";
import { updateCred } from "../../../../reducers/credentialsSlice";

type UpdateCredential = {
  title: string;
  beaconProjectId: string;
  credential: ICredential;
  onSuccess?: () => void;
  onError?: () => void;
}

const useUpdateCredential = () => {
  // context
  const userModel = useContext(UserContext);

  // store
  const dispatch = useCredentialAppDispatch();

  // api
  const [updateCredMutation, { isLoading: isUpdating }] = useUpdateCredMutation();

  const updateCredential = useCallback(async ({
    title,
    beaconProjectId,
    credential,
    onSuccess,
    onError
  }: UpdateCredential) => {
    const credParams: IUpdateCredentialPayload = {
      credential: {
        ...credential,
        name: title,
        project:
          beaconProjectId || credential.project
            ? { project_id: beaconProjectId || credential.project.project_id }
            : undefined,
      },
      joid: userModel?.getUser().uid ?? "",
    };
    try {
      const updatedCred = await updateCredMutation(credParams).unwrap();
      const _cred = Object.assign({}, updatedCred?.data);
      dispatch(updateCred(_cred));

      onSuccess && onSuccess();
    } catch (error) {
      console.error("Failed to updated credential");
      onError && onError();
    } finally {
      return;
    }
  }, [userModel, updateCred]);

  return {
    updateCredential,
    isUpdating
  }
};

export default useUpdateCredential;