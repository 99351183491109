import { useEffect } from "react";
import { useCredentialAppDispatch } from "./storeHooks";
import { useSearchCredsQuery } from "../services/credentialMatches";
import { ISearchCredsFilter } from "../types/credential";
import { addCreds } from "../reducers/credentialsSlice";
import { notification } from "maui";


interface IUsePopulateCredsList {
  pageNumber: number;
  joid: string;
  filters?: ISearchCredsFilter[];
  pageSize: number;
}

const usePopulateCredsList = ({
  pageNumber,
  joid,
  filters,
  pageSize = 10
}: IUsePopulateCredsList) => {
  const dispatch = useCredentialAppDispatch();


  const {
    data: credentialsData,
    isFetching: isCredentialsPending,
    isError: isCredentialsError
  } = useSearchCredsQuery({
    page: pageNumber,
    size: pageSize,
    joid: joid,
    filters: filters
  });

  useEffect(() => {
    if (!isCredentialsPending && credentialsData) {
      dispatch(addCreds(credentialsData.data));
    }

    if (isCredentialsError) {
      notification.error({
        message: 'Error',
        description: 'Error in fetching Credentials'
      });
    }

  }, [isCredentialsPending, isCredentialsError, credentialsData]);

  return {
    credentialsData,
    isCredentialsPending,
    isCredentialsError
  };
}

export default usePopulateCredsList;