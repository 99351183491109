import { createApi } from '@reduxjs/toolkit/query/react';
import { pubUrl } from 'findem-helpers';
import customBaseQuery from './baseQuery';
import { ICompanyProfile, IEdgarFinancialInfo } from '../types/pub';

export interface ICapiqCompanyData {
  company_id?: string;
  org_type_raw?: string;
}

interface ICompanyParams {
  meta_refs: string[];
  competitors_refs?: number;
  joid?: string;
}

interface IResponse {
  data: CompanyData;
}

export interface CompanyData {
  companyListDetails: ICompanyProfile[];
  edgarFinancialInfo?: IEdgarFinancialInfo[];
}

export const pubApi = createApi({
  reducerPath: 'pub',
  baseQuery: (args, api, extraOptions) =>
    customBaseQuery(args, api, extraOptions, pubUrl),
  tagTypes: ['getProfiles'],
  endpoints: (build) => ({
    getCompany: build.query<CompanyData, ICompanyParams>({
      query: ({ meta_refs, competitors_refs: _competitors_refs, joid }) => ({
        url: `pub/api/company_details?meta_refs=${meta_refs}${joid ? `&joid=${joid}` : ''}`,
        credentials: 'include',
        method: 'GET'
      }),
      transformResponse: (response: IResponse, meta, arg) =>
        response.data || { companyListDetails: [], edgarFinancialInfo: [] }
    }),
  })

});

export const {
  endpoints,
  useGetCompanyQuery
} = pubApi;
