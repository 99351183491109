import { Button, Modal } from "maui";

interface IDeleteCredentialProps {
  open: boolean;
  isDeleteCredPending: boolean;
  handleCloseDeleteModal: () => void;
  handleDelete: () => Promise<void>;
}

const DeleteCredential = ({
  open,
  isDeleteCredPending,
  handleCloseDeleteModal,
  handleDelete
}: IDeleteCredentialProps) => {

  return (
    <Modal
      title="Are you sure you want to delete?"
      open={open}
      footer={[
        <Button
          key="cancel"
          onClick={handleCloseDeleteModal}
        >
          Cancel
        </Button>,
        <Button
          key="confirm"
          type="primary"
          onClick={handleDelete}
          loading={isDeleteCredPending}
        >
          Confirm
        </Button>
      ]}
      onOk={handleDelete}
      onCancel={handleCloseDeleteModal}
    >
      Deleting this Credential is a permanent. There is no way to undo this action.
    </Modal>
  );
};

export default DeleteCredential;