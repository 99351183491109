import React, { useContext } from "react";
// style
import { FilterContainer, SelectCompWithMargin } from "./Filters.styled";
// components
import { Input, Spin, theme } from "maui";
// types
import { IFilterObj, IFilterOptions, IFilterProps, IOption } from "./types";
import { FilterObj, FilterType } from "./constants";
import { debounce, set } from "lodash";
import { useLazyGetCredBuilderFiltersACQuery } from "../../../services/credentialMatches";
import { ICredBuilderFiltersACResp } from "../../../types/credential";
import UserContext from "../../../../../contexts/UserContext";

const Filters: React.FC<IFilterProps> = ({
  onFilterChange,
  filterObj,
  filtersData,
}) => {
  const [filterValues, setFilterValues] = React.useState<IFilterObj>({});

  const [options, setOptions] = React.useState<IOption[]>([]);

  // context user
  const userModel = useContext(UserContext);

  // api
  const [
    filtersAC,
    { isFetching: isAutoCompletePending, isLoading: isAutoCompleteLoading },
  ] = useLazyGetCredBuilderFiltersACQuery();

  // const selectOptions: IFilterOptions = React.useMemo(() => {
  //   return filtersData
  //     ? filtersData
  //     : {
  //         client_name: [],
  //         title: [],
  //         label: [],
  //         industry: [],
  //         region: [],
  //         capability: [],
  //       };
  // }, [filtersData]);

  const selectFilters: string[] = React.useMemo(() => {
    const list: string[] = [];
    Object.keys(FilterObj).map((key: string) => {
      if (FilterObj[key] && key !== FilterType.DATE_RANGE) {
        list.push(key);
      }
    });
    return list;
  }, []);

  const handleDateRangeChange = (dateRange: [string, string]) => {
    // onFilterChange({ ...filterObj, date_range: dateRange });
  };
  const handleFiltersChange = React.useCallback(
    (key: string, value: string) => {
      if (value === "-1") {
        return;
      }
      // filter out current key
      onFilterChange({
        ...filterObj,
        [key]: {
          field: FilterObj[key].keyword ?? key,
          operator: FilterObj[key].operator,
          value: value,
        },
      });
    },
    [filterObj, onFilterChange]
  );

  const onNameChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {},
    [handleFiltersChange]
  );

  const handleSearch = React.useCallback(
    debounce(async (input: string, filter: string) => {
      if (input) {
        setOptions([
          {
            label: "Loading...",
            value: "-1",
          },
        ]);
        const response = await filtersAC({
          prefix: input,
          field: FilterObj[filter].keyword ?? filter,
          joid: userModel.getUser().uid,
        });
        if ("data" in response) {
          const result: ICredBuilderFiltersACResp | undefined = response.data;
          if (result?.data) {
            setOptions(
              result.data.map((item) => ({
                label: item.value,
                value: item.value,
              }))
            );
          }
        }
      } else {
        setOptions([]);
      }
    }, 500),
    []
  );

  React.useEffect(() => {
    setFilterValues(filterObj);
  }, [filterObj]);

  return (
    <FilterContainer>
      {/* Search by name */}
      <Input
        allowClear={true}
        isCollapsible={true}
        onChange={onNameChange}
        debounceDelay={100}
        placeholder={"Search by project name"}
        style={{
          width: "210px",
          marginRight: theme.dimensions.spacing[1],
        }}
      />
      {/* Filters of type DD */}
      {selectFilters.map((filter: string) => (
        <SelectCompWithMargin
          showSearch={true}
          allowClear={true}
          dropdownMatchSelectWidth={false}
          onSearch={(input: string) => handleSearch(input, filter)}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          key={filter}
          placeholder={FilterObj[filter].label}
          value={
            filterValues && filterValues[filter] && filterValues[filter]?.value
              ? filterValues[filter]
              : undefined
          }
          onChange={(value: any) => handleFiltersChange(filter, value)}
          options={options}
          notFoundContent={
            isAutoCompletePending || isAutoCompleteLoading ? (
              <Spin size="small" />
            ) : null
          }
          onBlur={() => setOptions([])}
        />
      ))}
    </FilterContainer>
  );
};

export default Filters;
