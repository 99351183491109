import { useState, useContext, useMemo, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
// style
import {
  CredBuilderContainer,
  FilterWrapper,
  Heading,
  NewCredentialButton,
} from "./Credentials.styled";
// components
import Filters from "./Filters";
import CredentialsTable from "./Table/CredentialsTable";
import CreateCredential from "../Modals/CreateCredential";
// context
import UserContext from "../../../../contexts/UserContext";
// types
import { IFilterObj, IFilterOptions } from "./Filters/types";
import { ISearchCredsFilter } from "../../types/credential";
// constants
import { FilterType } from "./Filters/constants";
// apis
import {
  credentialMatchesApi,
  useCreateCredMutation,
  useGetAtsJobsQuery,
} from "../../services/credentialMatches";
// hooks
import usePopulateCredsList from "../../hooks/usePopulateCredsList";
import useIndustries from "../../../../customHooks/useIndustries";
// reducers
import { useDispatch } from "react-redux";

export const PER_PAGE = 10;

const Credentials = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showNewCredentialModal, setShowNewCredentialModal] =
    useState<boolean>(false);
  const [filterObj, setFilterObj] = useState<IFilterObj>({});
  const [pageNumber, setPageNumber] = useState<number>(0);

  // context user
  const userModel = useContext(UserContext);

  // hooks
  const { industries } = useIndustries();

  // memo
  const filters: ISearchCredsFilter[] | undefined = useMemo(() => {
    const _filters: ISearchCredsFilter[] = [];
    Object.values(filterObj).forEach(
      (filter: ISearchCredsFilter | undefined) => {
        if (filter && filter.value) {
          _filters.push(filter);
        }
      }
    );
    return _filters;
  }, [filterObj]);

  // api
  const { data: atsJobs, isFetching: isAtsJobsPending } = useGetAtsJobsQuery({
    joid: userModel.getUser().uid,
  });
  const { credentialsData, isCredentialsPending, isCredentialsError } =
    usePopulateCredsList({
      pageNumber,
      joid: userModel.getUser().uid,
      pageSize: PER_PAGE,
      filters: filters,
    });
  const [createCredential, { isLoading: isUpdating }] = useCreateCredMutation();

  // memo
  const filtersData: IFilterOptions = useMemo(() => {
    const _options: IFilterOptions = {
      [FilterType.CLIENT_NAME]: [],
      [FilterType.TITLE]: [],
      [FilterType.INDUSTRY]: [],
      [FilterType.REGION]: [],
      [FilterType.LABEL]: [],
      [FilterType.CAPABILITY]: [],
    };

    atsJobs?.forEach((job) => {
      if (job.job_details?.BeaconID) {
        if (job.job_details?._client_company_name) {
          _options[FilterType.CLIENT_NAME].push({
            value: job.job_details?._client_company_name,
            label: job.job_details?._client_company_name,
          });
        }
        _options[FilterType.TITLE].push({
          value: job.job_details?.PositionTitle,
          label: job.job_details?.PositionTitle,
        });
        _options[FilterType.LABEL].push({
          value: job.job_details?.Label,
          label: job.job_details?.Label,
        });
      }
    });

    industries?.forEach((industry) => {
      if (!industry) return;
      _options[FilterType.INDUSTRY].push({
        value: industry,
        label: industry,
      });
    });

    return _options;
  }, [atsJobs, industries]);

  // handlers
  const showNewCredentialModalHandler = () => {
    setShowNewCredentialModal(true);
  };
  const closeNewCredentialModalHandler = () => {
    setShowNewCredentialModal(false);
  };
  const createCredentialHandler = (title: string, beaconProjectId: string) => {
    createCredential({
      name: title,
      joid: userModel.getUser().uid,
      project_id: beaconProjectId,
    }).then((resp) => {
      if ("data" in resp) {
        dispatch(credentialMatchesApi.util.invalidateTags(["Credentials"]));
        navigate(`credentials/${resp.data?.data.ref}`);
      }
      closeNewCredentialModalHandler();
    });
  };

  const handlePageChange = useCallback(
    (page: number, pageSize: number): void => {
      setPageNumber(page - 1);
    },
    []
  );

  return (
    <CredBuilderContainer>
      <Helmet>
        <title>Credentials | Findem</title>
      </Helmet>
      <Heading>
        <h2>Cred Builder</h2>
        <p>
          Search the entire RRA work history and effortlessly generate
          personalized Credential presentations.
        </p>
        <NewCredentialButton
          type="primary"
          onClick={showNewCredentialModalHandler}
        >
          New Credential
        </NewCredentialButton>
      </Heading>
      <FilterWrapper>
        <Filters
          onFilterChange={setFilterObj}
          filterObj={filterObj}
          filtersData={filtersData}
        />
      </FilterWrapper>
      <CredentialsTable
        data={credentialsData?.data ?? []}
        total={credentialsData?.count ?? 0}
        pageChange={handlePageChange}
        isPending={isCredentialsPending}
      />
      {showNewCredentialModal && (
        <CreateCredential
          onClose={closeNewCredentialModalHandler}
          onGenerate={createCredentialHandler}
          onCreateManually={() => {}}
        />
      )}
    </CredBuilderContainer>
  );
};

export default Credentials;
