import { Button, Table } from "maui";
import styled from "styled-components";

export const TableWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
`;

export const StyledTable = styled(Table)`
  margin-top: 20px;
  width: 100%;
  padding: 0px ${({ theme }) => theme.dimensions.spacing[4]};

  .ant-table-header {
    height: 46px;

    * {
      height: 46px;
      color: ${({ theme }) => theme.colors['grey-600']};
      font-weight: 400;
    }
  }

  .cred-list-rows {
    cursor: pointer;
  }
`;

export const CheckboxWrapper = styled.span`
  // margin-left: 12px;
  margin-right: 8px;
`;

export const CredentialName = styled.span`
  color: ${({ theme }) => theme.colors['grey-700']};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
`;

export const ItemContainer = styled.div`
  display: flex;
  vertical-align: -2px;
  color: ${({ theme }) => theme.colors['grey-500']};
  gap: 4px;
`;