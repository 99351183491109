import { router } from 'react-query-kit'
import apps from './apps';
import cardComments from './card-comments';
import roadmapCards from './roadmap-cards';
import users from './users'
import ats from './ats';
import common from './common';
import credential from './credential';


const userApis = router('users', users);
const appsApis = router('apps', apps);
const roadmapCardsApis = router('roadmapCards', roadmapCards);
const cardCommentsApis = router('cardComments', cardComments);
const atsApis = router('ats', ats);
const commonApis = router('common', common);
const credentialApis = router('credential', credential);

export {
  userApis,
  appsApis,
  roadmapCardsApis,
  cardCommentsApis,
  atsApis,
  commonApis,
  credentialApis,
};