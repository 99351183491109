import axios from "axios";
import { matchesUrl, pubUrl, searchUrl } from "findem-helpers";
import { router } from 'react-query-kit';
import { mockDataMiddleWare } from "../Middlewares/mockData";

const users = {
  getUserById: router.query({
    fetcher: (variables: { id: string }) =>
    axios.post(`${pubUrl}/pub/api/user_info`,{id: variables.id}, { withCredentials: true}),

    use: [
      // @ts-ignore
      mockDataMiddleWare
    ]
  }),
  
  getCurrentUser: router.query({
    fetcher: () => axios.get(`${matchesUrl}/api/user_info`, { withCredentials: true }),
    use: [
      // @ts-ignore
      mockDataMiddleWare
    ]
  }),
  
  logoutUser: router.mutation({
    mutationFn: async () =>
      axios.get(`${matchesUrl}/auth/logout?api=true`, { withCredentials: true })
  }),
  
  getCsrfToken: router.query({
    fetcher: () => axios.get(`${matchesUrl}/auth/csrf_token`, { withCredentials: true })
  }),
  
  authenticateUser: router.mutation({
    mutationFn: async (params: { email: string, password: string, csrf: string }) =>
      axios.post(`${searchUrl}/auth/login`, { api: true, _csrf: params.csrf, email: params.email, password: params.password }, { withCredentials: true })
  }),
  updateUserAppPrefs: router.mutation({
    mutationFn: async (params: { uid: string, apps: string[]}) =>
      axios.post(`${matchesUrl}/hm/api/user/pref`, params, { withCredentials: true })
  }),
  getUserPrefs: router.query({
    fetcher: (variables: { joid: string }) => axios.get(`${matchesUrl}/hm/api/user/pref?joid=${variables.joid}`, { withCredentials: true })
  }),
};

export default users;