import axios from "axios";
import { searchUrl } from "findem-helpers";
import { router } from 'react-query-kit';

const common = {
  getIndustriesList: router.query({
    fetcher: () => axios.get(`${searchUrl}/pub/api/industrylist`, { withCredentials: true }),
  }),
  getCompanies: router.mutation({
    mutationFn: async (variables: { term: string }) => {
      return axios.post(`${searchUrl}/pub/api/company_ac`, {
        prefix: variables.term, count: 15
      }, { withCredentials: true });
    },
  }),
  getTitles: router.mutation({
    mutationFn: async (variables: { term: string }) => {
      return axios.post(`${searchUrl}/pub/api/query_suggest`, {
        prefix: variables.term, types: 'T', count: 15
      }, { withCredentials: true });
    },
  }),
  getSkills: router.mutation({
    mutationFn: async (variables: { term: string }) => {
      return axios.post(`${searchUrl}/pub/api/profile-skills`, {
        prefix: variables.term, type: 'SearchProfileSkills', count: 15
      }, { withCredentials: true });
    },
  }),
  getMacros: router.query({
    fetcher: (variables: { joid: string }) =>
      axios.post(`${searchUrl}/pub/api/fetch_queries?joid=${variables.joid}`, {
        joid: variables.joid
      }, { withCredentials: true }),
  }),
  getCapIqIndustries: router.query({
    fetcher: (variables: { joid: string }) => axios.get(`${searchUrl}/pub/api/ciq_industrylist?joid=${variables.joid}`, { withCredentials: true }),
  }),
  getProductCategories: router.query({
    fetcher: () => axios.get(`${searchUrl}/pub/api/pcats`, { withCredentials: true }),
  }),

};

export default common;