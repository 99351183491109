import Creds from "../../components/CredentialApp/components/List";
import { IApp } from "../../types/app";

const isStaging = window.location.hostname.includes('dev');

export const DUMMY_APPS: IApp[] = [
  {
    icon: 'person_search',
    name: 'People Finder',
    id: 'people_finder',
    description: 'Search by attributes across RRA’s 2 million updated and enriched candidates.',
    launchUrl: `${isStaging ? 'https://rra-staging.findem.ai' : 'https://rra-app.findem.ai'}/searches`,
    tags: ['ATS Candidates'],
    bookmarked: true,
    isFindemApp: true,
    isBeta: true
  },
  {
    icon: 'apartment',
    name: 'Credential Builder',
    id: 'cred_builder',
    description: 'Search the entire RRA work history and effortlessly generate personalized Credential presentations',
    launchUrl: 'cred-builder',
    bookmarked: true,
    isFindemApp: true,
    isBeta: true,
    isCSR: true,
    component: Creds
  },
  {
    icon: 'person_search',
    name: 'RRA Engage (DCP)',
    id: 'rra_engage',
    description: `RRA Engage is RRA's custom, digital collaboration platform, designed to redefine how we interact and build relationship with our clients, candidates, and communities.`,
    launchUrl: `https://engage.russellreynolds.com/`,
    bookmarked: false,
    isFindemApp: false,
    isBeta: false
  },
  // {
  //   icon: 'RRALoginLogoIcon',
  //   name: 'Credential Builder',
  //   id: 'credential_builder',
  //   description: 'Build and manage your credentials.',
  //   launchUrl: `https://cb.russellreynolds.com/`,
  //   bookmarked: true,
  //   isFindemApp: false,
  //   isBeta: false
  // },
  // {
  //   icon: 'RRALoginLogoIcon',
  //   name: 'Opportunity Dashboard',
  //   id: 'opportunity_dashboard',
  //   description: 'View and manage your opportunities.',
  //   launchUrl: `http://innovation.russellreynolds.com/OD/OpportunityDashboard/OpportunityDashboard`,
  //   bookmarked: true,
  //   isFindemApp: false,
  //   isBeta: false
  // },
  // {
  //   icon: 'RRALoginLogoIcon',
  //   name: 'Athena',
  //   id: 'athena',
  //   description: 'Athena is a platform that helps you to manage your opportunities.',
  //   launchUrl: `https://athena.russellreynolds.com/`,
  //   bookmarked: true,
  //   isFindemApp: false,
  //   isBeta: false
  // },
  // {
  //   icon: 'RRALoginLogoIcon',
  //   name: 'Agiloft Contracts',
  //   id: 'agiloft_contracts',
  //   description: 'Agiloft Contracts is a platform that helps you to manage your contracts.',
  //   launchUrl: `https://agiloft.russellreynolds.com`,
  //   bookmarked: true,
  //   isFindemApp: false,
  //   isBeta: false
  // },
  // {
  //   icon: 'RRALoginLogoIcon',
  //   name: 'Hub Leader Dashboard',
  //   id: 'hub_leader_dashboard',
  //   description: 'Hub Leader Dashboard is a platform that helps you to manage your opportunities.',
  //   launchUrl: `https://russellreynoldsassociates.crm.dynamics.com/main.aspx?appid=a583a6a2-8891-49f4-8a9b-a1a65110be88&pagetype=dashboard&id=2810f7b9-527e-ea11-a812-000d3a329613`,
  //   bookmarked: true,
  //   isFindemApp: false,
  //   isBeta: false
  // },
  {
    icon: 'RRALoginLogoIcon',
    name: 'CIT',
    id: 'cit',
    description: `CIT is the Firm's Client Relationship Management tool used to help manage and provide insights regarding client relationships, BD/PNB pipeline, marketing efforts, and each Consultants' book of business.`,
    launchUrl: `https://russellreynoldsassociates.crm.dynamics.com/`,
    bookmarked: false,
    isFindemApp: false,
    isBeta: false
  },
  {
    icon: 'RRALoginLogoIcon',
    name: 'Beacon',
    id: 'beacon',
    description: `Proprietary global database which tracks all people, companies and engagements known to RRA.`,
    launchUrl: `beacon:///`,
    bookmarked: false,
    isFindemApp: false,
    isBeta: false
  },
  // {
  //   icon: 'RRALoginLogoIcon',
  //   name: 'Peoplesoft - GHC',
  //   id: 'peoplesoft_ghc',
  //   description: 'Peoplesoft - GHC is a platform that helps you to manage your opportunities.',
  //   launchUrl: `https://ghc.russellreynolds.com/`,
  //   bookmarked: true,
  //   isFindemApp: false,
  //   isBeta: false
  // },
  // {
  //   icon: 'RRALoginLogoIcon',
  //   name: 'Assessment Portal',
  //   id: 'assessment_portal',
  //   description: 'Peoplesoft - RRA is a platform that helps you to manage your opportunities.',
  //   launchUrl: `https://lsdiagnostics.russellreynolds.com/`,
  //   bookmarked: true,
  //   isFindemApp: false,
  //   isBeta: false
  // },
  {
    icon: 'RRALoginLogoIcon',
    name: 'Netdocs',
    id: 'netdocs',
    description: `NetDocs is RRA's document management system.`,
    launchUrl: `https://vault.netvoyage.com/neWeb2/home`,
    bookmarked: false,
    isFindemApp: false,
    isBeta: false
  },
  // {
  //   icon: 'RRALoginLogoIcon',
  //   name: 'PE Dashboard',
  //   id: 'pe_dashboard',
  //   description: 'PE Dashboard is a platform that helps you to manage your opportunities.',
  //   launchUrl: `https://mlmatching.russellreynolds.com:495/pedashboard/index`,
  //   bookmarked: true,
  //   isFindemApp: false,
  //   isBeta: false
  // },
  // {
  //   icon: 'RRALoginLogoIcon',
  //   name: 'Key & Emerging Relationship Dashboard',
  //   id: 'key_emerging_relationship_dashboard',
  //   description: 'Key & Emerging Relationship Dashboard is a platform that helps you to manage your opportunities.',
  //   launchUrl: `https://russellreynoldsassociates.crm.dynamics.com/main.aspx?appid=a583a6a2-8891-49f4-8a9b-a1a65110be88&forceUCI=1&pagetype=dashboard&id=791404e5-2308-4ac1-8162-70cf8425fc3f`,
  //   bookmarked: true,
  //   isFindemApp: false,
  //   isBeta: false
  // },
  // {
  //   icon: 'RRALoginLogoIcon',
  //   name: 'Consultant Dashboard',
  //   id: 'consultant_dashboard',
  //   description: 'Consultant Dashboard is a platform that helps you to manage your opportunities.',
  //   launchUrl: `https://russellreynoldsassociates.crm.dynamics.com/main.aspx?appid=a583a6a2-8891-49f4-8a9b-a1a65110be88&pagetype=webresource&webresourceName=rra_ConsultantDashboardV2.0`,
  //   bookmarked: true,
  //   isFindemApp: false,
  //   isBeta: false
  // },
  // {
  //   icon: 'RRALoginLogoIcon',
  //   name: 'Sector Dashboard',
  //   id: 'sector_dashboard',
  //   description: 'Sector Dashboard is a platform that helps you to manage your opportunities.',
  //   launchUrl: `https://russellreynoldsassociates.crm.dynamics.com/main.aspx?appid=a583a6a2-8891-49f4-8a9b-a1a65110be88&forceUCI=1&pagetype=webresource&webresourceName=rra_SectorDashboard`,
  //   bookmarked: true,
  //   isFindemApp: false,
  //   isBeta: false
  // },
  // {
  //   icon: 'RRALoginLogoIcon',
  //   name: 'Easysearch',
  //   id: 'easysearch',
  //   description: 'Easysearch is a platform that helps you to manage your opportunities.',
  //   launchUrl: `https://easysearch.russellreynolds.com/`,
  //   bookmarked: true,
  //   isFindemApp: false,
  //   isBeta: false
  // },
  // {
  //   icon: 'RRALoginLogoIcon',
  //   name: 'ThoughtSpot',
  //   id: 'thoughtspot',
  //   description: 'ThoughtSpot is a platform that helps you to manage your opportunities.',
  //   launchUrl: `https://thoughtspot.russellreynolds.com/#/`,
  //   bookmarked: true,
  //   isFindemApp: false,
  //   isBeta: false
  // },
  // {
  //   icon: 'RRALoginLogoIcon',
  //   name: 'Client Feedback',
  //   id: 'client_feedback',
  //   description: 'Client Feedback is a platform that helps you to manage your opportunities.',
  //   launchUrl: `http://dashboards/ClientFeedback/Pages/ClientFeedbackDashboard.aspx`,
  //   bookmarked: true,
  //   isFindemApp: false,
  //   isBeta: false
  // },
  // {
  //   icon: 'RRALoginLogoIcon',
  //   name: 'BCAP Pricing Dashboard',
  //   id: 'bcap_pricing_dashboard',
  //   description: 'BCAP Pricing Dashboard is a platform that helps you to manage your opportunities.',
  //   launchUrl: `https://russellreynoldsassociates.crm.dynamics.com/main.aspx?appid=a583a6a2-8891-49f4-8a9b-a1a65110be88&pagetype=dashboard&id=50951708-4bb6-ec11-983e-000d3a5a747c`,
  //   bookmarked: true,
  //   isFindemApp: false,
  //   isBeta: false
  // },
  // {
  //   icon: 'RRALoginLogoIcon',
  //   name: 'Client Master',
  //   id: 'client_master',
  //   description: 'Client Master is a platform that helps you to manage your opportunities.',
  //   launchUrl: `https://edmappserver1.russellreynolds.com:470/`,
  //   bookmarked: true,
  //   isFindemApp: false,
  //   isBeta: false
  // },
  // {
  //   icon: 'RRALoginLogoIcon',
  //   name: 'Measurement Dashboard',
  //   id: 'measurement_dashboard',
  //   description: 'Measurement Dashboard is a platform that helps you to manage your opportunities.',
  //   launchUrl: `http://bi.russellreynolds.com/Finance/Pages/MeasurementDashboard.aspx`,
  //   bookmarked: true,
  //   isFindemApp: false,
  //   isBeta: false
  // },
  // {
  //   icon: 'RRALoginLogoIcon',
  //   name: 'Slack',
  //   id: 'slack',
  //   description: 'Slack is a platform that helps you to manage your opportunities.',
  //   launchUrl: `http://rra.slack.com/`,
  //   bookmarked: true,
  //   isFindemApp: false,
  //   isBeta: false
  // },
  // {
  //   icon: 'RRALoginLogoIcon',
  //   name: 'Concur',
  //   id: 'concur',
  //   description: 'Concur is a platform that helps you to manage your opportunities.',
  //   launchUrl: `https://concur.russellreynolds.com/`,
  //   bookmarked: true,
  //   isFindemApp: false,
  //   isBeta: false
  // },
  {
    icon: 'RRALoginLogoIcon',
    name: 'BoardEx',
    id: 'boardex',
    description: `BoardEx offers solutions to advance business development and enhance relationship capital management.`,
    launchUrl: `https://russellreynolds.boardex.com/web`,
    bookmarked: false,
    isFindemApp: false,
    isBeta: false
  },
  {
    icon: 'RRALoginLogoIcon',
    name: 'CapIQ',
    id: 'capiq',
    description: `CapIQ is a market intelligence platform designed by Standard & Poor’s (S&P)`,
    launchUrl: `http://capitaliq.russellreynolds.com`,
    bookmarked: false,
    isFindemApp: false,
    isBeta: false
  },
  // {
  //   icon: 'RRALoginLogoIcon',
  //   name: 'Business Intelligence Dashboard ',
  //   id: 'business_intelligence_dashboard',
  //   description: 'Business Intelligence Dashboard is a platform that helps you to manage your opportunities.',
  //   launchUrl: `http://bi.russellreynolds.com/finance/Dashboards/Firm%20Performance%20Review/Home%20Page.aspx`,
  //   bookmarked: true,
  //   isFindemApp: false,
  //   isBeta: false
  // },
  // {
  //   icon: 'RRALoginLogoIcon',
  //   name: 'Rconnect',
  //   id: 'rconnect',
  //   description: 'Rconnect is a platform that helps you to manage your opportunities.',
  //   launchUrl: `https://rraconnect.russellreynolds.com/member/signin?returnUrl=https%3A%2F%2Frraconnect.russellreynolds.com%2Fmember%2F`,
  //   bookmarked: true,
  //   isFindemApp: false,
  //   isBeta: false
  // }
  {
    icon: 'RRALoginLogoIcon',
    name: 'PitchBook',
    id: 'pitchbook',
    description: `PitchBook is the leading resource for comprehensive data, research and insights spanning the global capital markets.`,
    launchUrl: `https://my.pitchbook.com/`,
    bookmarked: false,
    isFindemApp: false,
    isBeta: false
  },
  {
    icon: 'RRALoginLogoIcon',
    name: 'LinkedIn',
    id: 'linkedin',
    description: `LinkedIn is the world's largest professional network with more than 1 billion members in more than 200 countries and territories worldwide.`,
    launchUrl: `https://www.linkedin.com/`,
    bookmarked: false,
    isFindemApp: false,
    isBeta: false
  },
  {
    icon: 'RRALoginLogoIcon',
    name: 'GuideStar',
    id: 'guidestar',
    description: `GuideStar collects, organizes, and presents information about every single IRS-registered nonprofit organization.`,
    launchUrl: `https://www.guidestar.org/`,
    bookmarked: false,
    isFindemApp: false,
    isBeta: false
  },
  {
    icon: 'RRALoginLogoIcon',
    name: 'FastPath Portal',
    id: 'fastpath_portal',
    description: `Direct access to tactical tools for getting your job done`,
    launchUrl: `https://rra.russellreynolds.com/research/Pages/FastPath%20Portal.aspx`,
    bookmarked: false,
    isFindemApp: false,
    isBeta: false
  },
  {
    icon: 'RRALoginLogoIcon',
    name: 'Factiva',
    id: 'factiva',
    description: `Factiva is a business intelligence platform that includes content from 33,000 news, data and information sources from 200 countries and 32 languages. The platform contains millions of corporate profiles, as well as research tools to analyze media coverage.`,
    launchUrl: `http://factiva.russellreynolds.com/`,
    bookmarked: false,
    isFindemApp: false,
    isBeta: false
  },
  {
    icon: 'RRALoginLogoIcon',
    name: 'RRA ChatGPT',
    id: 'rra_chatgpt',
    description: `RRA ChatGPT is RRA’s version of OpenAI’s ChatGPT digital assistant, powered by AI and a large language model. It uses OpenAI’s model and dataset, but unlike OpenAI’s ChatGPT, proprietary RRA information can be safely entered into RRA ChatGPT.`,
    launchUrl: `https://rrachatgpt.russellreynolds.com/`,
    bookmarked: false,
    isFindemApp: false,
    isBeta: false
  },
  {
    icon: 'RRALoginLogoIcon',
    name: 'Writer',
    id: 'writer',
    description: `Writer is a full-stack generative AI platform specifically designed to assist with content creation, editing, and language-related tasks. Currently RRA has created both candidate letter and case study templates for our colleagues to leverage`,
    launchUrl: `https://app.writer.com/`,
    bookmarked: false,
    isFindemApp: false,
    isBeta: false
  },

  {
    icon: 'RRALoginLogoIcon',
    name: 'Nikkei Telecom',
    id: 'nikkei_telecom',
    description: `One of the largest databases in Japan. Provides a one-stop web service that allows you to search and collect company information, news articles, and industry reports from over 750 information sources.`,
    launchUrl: `https://t21.nikkei.co.jp/g3/CMN0F11.do`,
    bookmarked: false,
    isFindemApp: false,
    isBeta: false
  },

  {
    icon: 'RRALoginLogoIcon',
    name: 'BizReach',
    id: 'bizreach',
    description: `Japan’s foremost recruiting platform, exclusive to high-class executive jobs, with 830,000+ registered users.`,
    launchUrl: `https://auth.id.bizreach.jp/login?state=hKFo2SBPWG4zSjJrYUV5ekpTUW5wa2ZHdUlobG1ieGhEbkFEOKFupWxvZ2luo3RpZNkgLWhOclJjUFdGSmlqVHpkLWFheE9ncGVBOXJzVzVDeDOjY2lk2SBUOFRZWkxxUjJNVkp0cXhpRG8wamQ2aUJBYWZwTVFueQ&client=T8TYZLqR2MVJtqxiDo0jd6iBAafpMQny&protocol=oauth2&redirect_uri=https%3A%2F%2Fwww.bizreach.jp%2Fauth%2Flogin-callback&product=bizreach&matching_key=I8J5N8gVPtsHevAoYbq0vqRF&scope=openid%20email%20profile%20offline_access&response_type=code`,
    bookmarked: false,
    isFindemApp: false,
    isBeta: false
  },

  {
    icon: 'RRALoginLogoIcon',
    name: 'MergerMarket',
    id: 'mergermarket',
    description: `Mergermarket gives M&A professionals a competitive advantage, delivering insights on deals 6-24 months before they become public knowledge.`,
    launchUrl: `https://id.ionanalytics.com/signin?onSuccess=https%3A%2F%2Fmergermarket.ionanalytics.com%2F%3Fcountry%3D203%26country%3D202%26country%3D65%26_gl%3D1*12r7mey*_gcl_au*MTMyNjIxOTMyMS4xNzE4NzI2Mjgz*_ga*NTQyMjQ0Njg0LjE3MTg3MjYyODM.*_ga_FV5VK54NW9*MTcxODcyNjI4MC4xLjEuMTcxODcyNjI4My41Ny4wLjA.&d=1&edgeCorrelationId=0b9e9f1f-9e5b-430a-a998-08e1dd88d3f8`,
    bookmarked: false,
    isFindemApp: false,
    isBeta: false
  },

  {
    icon: 'RRALoginLogoIcon',
    name: 'BCIQ',
    id: 'bciq',
    description: `BCIQ is designed and curated by BioCentury's industry experts and data team to support the biopharma industry's financing, investing and dealmaking activities.`,
    launchUrl: `https://identity.biocentury.com/?redirect_uri=https:%2F%2Fbciq.biocentury.com%2F`,
    bookmarked: false,
    isFindemApp: false,
    isBeta: false
  },

  {
    icon: 'RRALoginLogoIcon',
    name: 'Qualtrics',
    id: 'qualtrics',
    description: `Qualtrics operates an experience management platform to design and manage customer, employee, product, and brand experiences worldwide.`,
    launchUrl: `https://login.qualtrics.com/login`,
    bookmarked: false,
    isFindemApp: false,
    isBeta: false
  }
]