import { Dropdown, Icon, Menu, theme } from "maui";
import { CredRef, ICredential } from "../../../types/credential";
import { ItemContainer } from "./CredentialsTable.styled";
import { EditDetailsModal } from "./CredentialsTable";

interface IActionProps {
  setDeleteCredModal: React.Dispatch<React.SetStateAction<{
    show: boolean;
    credRef: CredRef | null;
  }>>;
  setEditDetailsModal: React.Dispatch<React.SetStateAction<EditDetailsModal>>;
  credRef: CredRef;
  credential: ICredential;
}

const Action = ({
  setDeleteCredModal,
  setEditDetailsModal,
  credential,
  credRef
}: IActionProps) => {

  const menu = <Menu onClick={(ev) => {
    const key = ev.key;

    if (key === 'delete') {
      setDeleteCredModal({
        show: true,
        credRef: credRef
      });
    } else if (key === 'edit-details') {
      setEditDetailsModal({
        show: true,
        credential
      })
    }


  }}>
    <Menu.Item key="edit-details">
      <ItemContainer className="credential-action-edit-details">
        <Icon icon="edit" $color={theme.colors['grey-500']} $size={20} />Edit details
      </ItemContainer>
    </Menu.Item>
    <Menu.Item key="delete">
      <ItemContainer className="credential-action-delete">
        <Icon icon="delete" $color={theme.colors['grey-500']} $size={20} />Delete
      </ItemContainer>
    </Menu.Item>
  </Menu>
  return (
    <Dropdown overlay={menu} trigger={['click']} className="credential-action">
      <Icon
        icon="more_vert"
        $size={24}
        $color="#8A8A8A"
        style={{ cursor: 'pointer' }}
      />
    </Dropdown>
  );
};

export default Action;