import { TEMPLATE_KEYS_TYPE } from "../../../../types/credential";
import { KEYS, MENU_KEYS, MENU_OPTION } from "../types/common";

// type guard
export const isMenuKey = (key: any): key is MENU_KEYS => {
  return KEYS.includes(key);
}

export const MENU_OPTIONS: MENU_OPTION[] = [
  {
    'text': 'Preferences',
    'icon': 'tune',
    'key': 'preferences'
  },
  {
    'text': 'Layout',
    'icon': 'dashboard',
    'key': 'layout'
  },
  {
    'text': 'Presentation Preview',
    'icon': 'visibility',
    'key': 'presentation_preview'
  }
];

export const DEFAULT_TEMAPLTE: TEMPLATE_KEYS_TYPE = 'standard_credential';