import axios from "axios";
import { matchesUrl } from "findem-helpers";
import { router } from 'react-query-kit';

const ats = {
  getAtsJobs: router.query({
    fetcher: (variables: { joid: string, is_rra: boolean }) => axios.get(`${matchesUrl}/hm/api/ats-jobs?joid=${variables.joid}&is_rra=${variables.is_rra}`, { withCredentials: true }),
  }),
};

export default ats;