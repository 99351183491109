import { Button, Form, Input, Modal, Select } from "maui";
import React, { useContext } from "react";
import { ICredential } from "../../../types/credential";
import UserContext from "../../../../../contexts/UserContext";
import { useGetAtsJobsQuery } from "../../../services/credentialMatches";
import { IOption } from "../../List/Filters/types";
import { IATSJob } from "../../../types/ats";

interface ICreateCredentialProps {
  onClose: () => void;
  onGenerate: (title: string, beaconProjectId: string) => void | Promise<void>;
  onCreateManually: (title: string) => void;
  credential?: ICredential;
}

const CreateCredential: React.FC<ICreateCredentialProps> = ({
  onClose,
  onGenerate,
  onCreateManually,
  credential,
}) => {
  const [title, setTitle] = React.useState<string>("");
  const [beaconProjectId, setBeaconProjectId] = React.useState<string>("");
  const [isGenerating, setIsGenerating] = React.useState<boolean>(false);

  // context user
  const userModel = useContext(UserContext);

  // api
  const { data: atsJobs, isFetching: isAtsJobsPending } = useGetAtsJobsQuery({
    joid: userModel.getUser().uid,
  });

  // memo
  const projectOptions: IOption[] = React.useMemo(() => {
    if (!atsJobs) return [];
    return atsJobs.map((job: IATSJob) => ({
      label: `${job.job_name} (${job.job_details?.Label})`,
      value: job.ats_job_id,
    }));
  }, [atsJobs]);

  const handleGenerate = React.useCallback(() => {
    if (title === "") return;
    setIsGenerating(true);
    onGenerate(title, beaconProjectId);
  }, [title, beaconProjectId, onGenerate]);

  const handleCreateManually = React.useCallback(() => {
    if (title === "") return;
    setIsGenerating(true);
    onGenerate(title, beaconProjectId);
  }, [title, onGenerate, beaconProjectId]);

  const onSubmit = React.useCallback(async () => {
    if (credential) {
      setIsGenerating(true);
      await onGenerate(title, beaconProjectId);
      setIsGenerating(false);
    } else {
      handleCreateManually();
    }
  }, [credential, title, beaconProjectId, handleCreateManually]);

  React.useEffect(() => {
    if (credential) {
      setTitle(credential.name);
      setBeaconProjectId(credential.project?.project_id);
    }
  }, [credential]);

  return (
    <>
      <Modal
        title={credential ? "Update Credential" : "New Credential"}
        open={true}
        onCancel={onClose}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={onSubmit}
            loading={isGenerating}
            disabled={!title.length || isGenerating}
          >
            {credential ? "Update" : "Create"}
          </Button>
          ,
        ]}
      >
        <Form layout="vertical" initialValues={{ size: "default" }}>
          <Form.Item label="Credential Title">
            <Input
              width={"100%"}
              defaultValue={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter Credential title..."
            />
          </Form.Item>
          <Form.Item label="Link to Beacon Project">
            <Select
              options={projectOptions}
              value={beaconProjectId}
              placeholder="Select Beacon Project"
              onChange={(value) => setBeaconProjectId(value)}
              style={{ width: "100%" }}
              loading={isAtsJobsPending}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateCredential;
