import React from "react";
import { notification } from "maui";
// styled comp + components
import { CredentialName, StyledTable, TableWrapper } from "./CredentialsTable.styled";
import Action from "./Action";
// types
import { CredRef, ICredential } from "../../../types/credential";
// navigator
import { useNavigate } from "react-router-dom";
import { PER_PAGE } from "../Credentials";
import { decodeString } from "findem-helpers";
// api
import { credentialMatchesApi, useDeleteCredMutation } from "../../../services/credentialMatches";
import DeleteCredential from "../../Modals/DeleteCredential/DeleteCredential";
import CreateCredential from "../../Modals/CreateCredential";
import useUpdateCredential from "../../Modals/CreateCredential/hooks/useUpdateCredential";
import { useDispatch } from "react-redux";
// store

interface ICredentialsTableProps {
  data: ICredential[]; //credentials data
  total: number;
  isPending: boolean;
  pageChange: (page: number, pageSize: number) => void;
}

interface IRow {
  key: string;
  name: React.ReactNode;
  type?: string | undefined;
  label: string;
  client_name: string;
  hiring_manager: React.ReactNode;
}

export interface EditDetailsModal {
  show: boolean;
  credential: ICredential | null;
}

const CredentialsTable: React.FC<ICredentialsTableProps> = ({
  data,
  total,
  isPending,
  pageChange,
}) => {
  // store
  const dispatch = useDispatch();

  const [deleteCred, { isLoading: isDeleteCredPending }] = useDeleteCredMutation();

  const [deleteCredModal, setDeleteCredModal] = React.useState<{
    show: boolean;
    credRef: CredRef | null;
  }>({
    show: false,
    credRef: null
  });
  const [editDetailsModal, setEditDetailsModal] = React.useState<EditDetailsModal>({
    show: false,
    credential: null
  });

  const navigate = useNavigate();

  // custom hook
  const { updateCredential} = useUpdateCredential();

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleCheckboxClick = React.useCallback(
    (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    []
  );

  // on cred click go to cred details with path /my-apps/cred-builder/credentials/{credId}
  const handleCredClick = React.useCallback(
    (credId: string) => {
      navigate(`credentials/${credId}`);
    },
    [navigate]
  );

  const columns = React.useMemo(
    () => [
      {
        title: 'Credential Name',
        dataIndex: "name",
        key: "name",
        className: "cred-name",
        ellipsis: true,
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
      },
      {
        title: "Project Label",
        dataIndex: "label",
        key: "label",
      },
      {
        title: "Client Name",
        dataIndex: "client_name",
        key: "client_name",
      },
      {
        title: "Lead Consultant",
        dataIndex: "hiring_manager",
        key: "hiring_manager",
      }
    ],
    []
  );

  // TODO: balraj - check if we are picking proper fields here
  const dataSource: IRow[] = React.useMemo(() => {
    if (data && data.length > 0) {
      return data.map((credential) => ({
        key: credential.ref,
        name: <CredentialName>{decodeString(credential.name)}</CredentialName>,
        type: credential?.project?.type ?? "-",
        label: credential?.project?.label ?? "-",
        client_name: credential?.project?.client_company?.company_name ?? "-",
        hiring_manager: <div style={{ display: "flex", justifyContent: "space-between"}} data-key="hiring_manager">
          <div style={{ marginRight: "20px", gap: "4px"}}>{credential?.project?.team?.[0]?.name ?? "-"}</div>
          <Action
            setDeleteCredModal={setDeleteCredModal}
            setEditDetailsModal={setEditDetailsModal}
            credRef={credential.ref}
            credential={credential}
          />
        </div>
      }));
    }
    return [];
  }, [data]);

  const handleDelete = React.useCallback(async () => {
    try {
      const ref = deleteCredModal.credRef!

      await deleteCred({
        ref,
        //TODO: balraj add joid
      // joid:
      }).unwrap();

      notification.success({
        "message": "Successfully deleted the Credential"
      });

      setDeleteCredModal({
        credRef: null,
        show: false
      });
    } catch (error) {
      console.error("Error while deleting Credential: ", error);
      notification.error({
        "message": "Failed to delete the Credential"
      });
    }
  }, [deleteCredModal.credRef]);

  const handleCloseDeleteModal = React.useCallback(() => {
    setDeleteCredModal({
      show: false,
      credRef: null
    });
  }, []);

  const handleCloseEditDetailsModal = React.useCallback(() => {
    setEditDetailsModal({
      show: false,
      credential: null
    })
  }, []);

  const createCredentialHandler = React.useCallback(async (title: string, beaconProjectId: string) => {
    if (!editDetailsModal.credential) return;
    updateCredential({
      title,
      beaconProjectId,
      credential: editDetailsModal.credential,
      onSuccess: () => {
        notification.success({
          message: "Success",
          description: "Credential saved successfully",
        });
        setEditDetailsModal({
          show: false,
          credential: null
        });
        dispatch(credentialMatchesApi.util.invalidateTags(["Credentials"]));
      },
      onError: () => {
        notification.error({
          message: "Error",
          description: "Failed to update credential",
        });
      }
    });
  }, [editDetailsModal.credential, updateCredential]);

  return (
    <TableWrapper>
      <StyledTable
        scroll={{ y: 'calc(100vh - 450px)' }}
        columns={columns}
        dataSource={dataSource}
        pagination={{
          onChange: pageChange,
          pageSize: PER_PAGE,
          total: total,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (e) => {
              if (e.target instanceof HTMLElement) {
                const classList = Array.from(e.target.classList);

                // action dropdown menu clicked
                if (classList.some((className) => className.startsWith('credential-action'))) {
                  return;
                }
              }
              //! TODO: @balraj - antd table doesnt expose the types, find how other places does it
              // @ts-expect-error
              const key = record?.key as IRow['key'];
              handleCredClick(key);
            }
          }
        }}
        loading={isPending}
        rowClassName="cred-list-rows"
      />
      {deleteCredModal.show &&
        <DeleteCredential
          open={deleteCredModal.show}
          isDeleteCredPending={isDeleteCredPending}
          handleCloseDeleteModal={handleCloseDeleteModal}
          handleDelete={handleDelete}
        />
      }
      {editDetailsModal.show && editDetailsModal.credential && (
        <CreateCredential
          credential={editDetailsModal.credential}
          onClose={handleCloseEditDetailsModal}
          onGenerate={createCredentialHandler}
          onCreateManually={() => { }}
        />
      )}
    </TableWrapper>
  );
};

export default CredentialsTable;
